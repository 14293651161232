@import 'mixins.scss';

.footer {
  max-width: rem(1280);
  margin: 0 auto;
  padding: 0 rem(30);
  color: $text;

  @media (max-width: $sm-max-width) {
    padding: 0 rem(16) !important;
  }
}

.footerFullWidth {
  max-width: none;
}

.inner {
  border-top: 1px solid $border;
  padding: rem(30) 0;
}

.icons{
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.logo {
  color: $black;
  font-size: rem(15);
  text-transform: uppercase;
  letter-spacing: -1px;
  font-weight: 700;

  svg {
    position: relative;
    top: 3px;
    margin-right: rem(6);
    height: rem(16);
    width: rem(16);
    path:nth-child(1) {
      stroke: $primary;
      fill: $primary;
      stroke-width: 1px;
    }
  }
}

// dark theme
[data-vb-theme='dark'] {
  .footer {
    color: $dark-gray-1;
  }

  .inner {
    border-top: 1px solid $dark-gray-4;
  }
}
