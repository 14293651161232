// this is shared mixins file for all components from the app,
// there you can easily add your own variables / imports or redefine
// existing without touching default packages for future updates

@import 'src/@vb/css/mixins.scss'; // import default mixins

$blue: #6938C0;
$blue-light: #E50F41;

// page heading rtl
.vb__utils__heading {
  &__rtl {
    padding-right: rem(30);
    padding-left: 0;
    &:before {
      right: 0;
    }
  }
}

// load the fonts
@font-face {
  font-family: 'Dubai Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Dubai Regular'), url('./fonts/Dubai-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Dubai Light';
  font-style: normal;
  font-weight: normal;
  src: local('Dubai Light'), url('./fonts/Dubai-Light.woff') format('woff');
}

@font-face {
  font-family: 'Dubai Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Dubai Medium'), url('./fonts/Dubai-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Dubai Bold';
  font-style: normal;
  font-weight: normal;
  src: local('Dubai Bold'), url('./fonts/Dubai-Bold.woff') format('woff');
}

// Form item exstra font size smaller
.ant-form-item-extra {
  font-size: 11px !important;
}
// Steps description take one line
.ant-steps-item-description {
  white-space: nowrap !important;
}

// if lang ar change the font, direction and text align
[lang='ar'] {
  body {
    font-family: 'Dubai Regular', Helvetica, sans-serif !important;
    text-align: right !important;
  }
  .ant-form-rtl .ant-form-item-label{
    text-align: right !important;
  }
  input {
    font-family: 'Dubai Regular', Helvetica, sans-serif !important;
  }

  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
    height: 42.5px;
  }
}

// to fix ant d border-radius in rtl
[data-vb-theme='default'] {
  .ant-input-group-rtl .ant-input-group-addon:last-child {
    border-radius: 8px 0 0 8px;
  }
  .ant-table-rtl .ant-table-container table > thead > tr:first-child {
    th:first-child {
      border-top-right-radius: 8px;
      border-top-left-radius: 0;
    }
    th:last-child {
      border-top-right-radius: 0;
      border-top-left-radius: 8px;
    }
  }

  .ant-card-rtl {
    .ant-card-actions > li:not(:last-child) {
      border-left: 1px solid #e4e9f0;
      border-right: none;
    }
  }

  .ant-input-number{
    width: 100%;
  }
}
